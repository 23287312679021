<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">{{ $t('views.centrals.enrollment.title') }}</h1>
            </v-col>
            <!-- load the central_drawing.svg into the middle -->
            <v-col
                align-self="center"
                class=""
                offset="1"
                offset-lg="0"
                cols="10"
                lg="6">
                <img
                    src="@/assets/images/central/central.png"
                    alt="Central"
                    class="tw-mt-6 tw-m-auto tw-w-1/2 tw-h-auto hover:tw-cursor-pointer"
                    v-if="activate == false"
                    @click="activate = true"
                />
                <p class="tw-text-center tw-text-base tw-mt-12"
                v-if="activate == false">
                    {{ $t('views.centrals.enrollment.description') }}
                </p>
                <!-- add text to click in the image to start the scan under the image-->

                <qrcode-stream
                    class="xl:tw-w-4/5 tw-h-auto"
                    @error="onError"
                    @detect="onDetect"
                    v-if="activate == true">
                </qrcode-stream>
            </v-col>
            <v-col
                align-self="center"
                offset="1"
                offset-lg="0"
                lg="6"
                cols="10">
                <!-- add title saying instructions -->
                <h2 class="tw-text-xl
                    tw-font-bold tw-mb-6">
                    {{ $t('views.centrals.enrollment.instructions') }}
                </h2>
                <!-- add a description to where to find the qr code -->
                <div class="tw-flex tw-gap-x-3 tw-my-3">
                    <v-icon
                        class="tw-text-primary tw-p-4 tw-rounded-md tw-bg-red-200 tw-bg-opacity-100"
                        >mdi-numeric-1</v-icon>
                    <p class="tw-text-base">
                        {{ $t('views.centrals.enrollment.qrCodeLocationDescription1') }}
                    </p>
                </div>
                <!-- add separating line -->

                <div class="tw-flex tw-gap-x-3 tw-my-3">
                    <v-icon
                        class="tw-text-primary tw-p-4 tw-rounded-md tw-bg-red-200 tw-bg-opacity-100"
                        >mdi-numeric-2</v-icon>
                    <p class="tw-text-base">
                        {{ $t('views.centrals.enrollment.qrCodeLocationDescription2') }}
                    </p>
                </div>

                <div class="tw-flex tw-gap-x-3 tw-my-3">
                    <v-icon
                        class="tw-text-primary tw-p-4 tw-rounded-md tw-bg-red-200 tw-bg-opacity-100"
                        >mdi-numeric-3</v-icon>
                    <p class="tw-text-base">
                        {{ $t('views.centrals.enrollment.qrCodeLocationDescription3') }}
                    </p>
                </div>

                <div class="tw-flex tw-gap-x-3 tw-my-3">
                    <v-icon
                        class="tw-text-primary tw-p-4 tw-rounded-md tw-bg-red-200 tw-bg-opacity-100"
                        >mdi-numeric-4</v-icon>
                    <p class="tw-text-base">
                        {{ $t('views.centrals.enrollment.qrCodeLocationDescription4') }}
                    </p>
                </div>

                <!-- add a button to start the process -->
                <div
                class="tw-flex tw-justify-center">
                    <v-btn
                    class="tw-mt-6"
                    size="large"
                    color="primary"
                    @click="activate = !activate"
                    >{{ activate ? $t('views.centrals.enrollment.stopProcess'): $t('views.centrals.enrollment.startProcess') }}</v-btn>
                </div>

            </v-col>
            <v-col
                v-if="isLoading"
                class="tw-mt-auto">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';
    import { ref } from 'vue';
    import { QrcodeStream } from 'vue-qrcode-reader'
    import { adminEnrollment } from '@/api/centrals';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';

    const { t } = useI18n();

    const $alert = useAlert();
    const $error = useError();
    const $router = useRouter();

    const isLoading = ref(false);
    const activate = ref(false);

    const onDetect = async (result: any) => {
        // disable the camera
        activate.value = false;

        // check if the result is valid
        // the result should be in the format of SN;key with SN having 5 characters and key having 64 characters
        const [SN, key] = result[0].rawValue.split(';');
        if (SN.length !== 5 || key.length !== 64) {
            $alert.showAlert({
                text: t('views.centrals.enrollment.invalidQRCode'),
                type: 'error',
            });
            return;
        }

        isLoading.value = true;

        try {
            await adminEnrollment(SN as string, {
                key: key as string,
            });

            $alert.showAlert({
                text: t('views.centrals.enrollment.addedCentralSuccessfully'),
                type: 'success',
            });

            // redirect to the dashboard
            $router.push('/');
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.centrals.enrollment'),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    };

    const onError = (error: string) => {
        console.error(error);
    };

</script>

<style scoped></style>
