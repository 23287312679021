<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col cols="12">
                <div class="tw tw-flex tw-items-center tw-gap-4">
                    <v-btn
                        @click="$router.go(-1)"
                        color="gray-300"
                        class="tw-rounded tw-text-xl"
                        size="small"
                        icon="mdi-chevron-left">
                    </v-btn>
                    <h1 class="tw-text-2xl tw-font-bold">{{ zone?.name }}</h1>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs
                    class="tw-p-0"
                    :items="breadcrumbs">
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-xl:tw-grid max-xl:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="large"
                    :color="index === navSelectedIndex ? 'primary' : 'gray-200'"
                    @click="navSelectedIndex = index"
                    >{{ item.title }}</v-btn
                >
            </v-col>
        </v-row>
        <router-view
            v-if="zone"
            :zone="zone" />
    </v-container>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getZone } from '@/api/zones';
    import { useI18n } from 'vue-i18n';
    import { CentralDevicesEventsCount } from '@/types';
    import { Zone } from '@/contentTypes';
    import { watch } from 'vue';
    import { computed } from 'vue';
    import { VBreadcrumbs } from 'vuetify/lib/components/index.mjs';
    import { useSocket } from '@/composables/useSocket';
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';

    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const $error = useError();
    const $socket = useSocket();
    const isLoading = ref(false);
    const { t } = useI18n();

    const zone = ref<Zone>();
    const deviceCounts = ref({} as CentralDevicesEventsCount);

    const navItems = computed(() => [
        {
            title: t('views.centrals.shared.buttons.devices'),
            to: { name: 'Zone-Devices', params: { id: $route.params.id } },
        },
        {
            title: t('views.centrals.shared.buttons.configurations'),
            to: { name: 'Zone-Config', params: { id: $route.params.id } },
        },
    ]);

    const breadcrumbs = computed(() => {
        return [
            {
                title: zone.value?.central?.name,
                disabled: false,
                to: { name: 'Central-Zones', params: { id: zone.value?.central?.id } },
            },
            {
                title: zone.value?.name,
                disabled: true,
            },
        ] as VBreadcrumbs['$props']['items'];
    });

    const navSelectedIndex = ref($route.name == 'Zone-Config' ? 1 : 0);

    async function init(shouldLoad = true) {
        isLoading.value = shouldLoad;
        let query = {
            populate: ['central'],
        };
        try {
            const { data } = await getZone($route.params.id as string, query);
            zone.value = data.data;
        } catch (error: any) {
            if (error.response.status === 404) {
                $router.push({ name: '404', params: { type: 'zone' } });
            } else {
                $alert.showAlert({
                    text: $error.getErrorMessage(error),
                    type: 'error',
                });
            }
        } finally {
            isLoading.value = false;
        }
    }

    let currentId: any = null;
    watch(
        () => $route.params.id,
        (newValue, oldValue) => {
            init();
            currentId = $route.params.id;
            $socket.on(`zones/${newValue}`, () => {
                init(false);
            });
            $socket.off(`zones/${oldValue}`);
        },
        {
            immediate: true,
        },
    );

    onBeforeUnmount(() => {
        $socket.off(`zones/${currentId}`);
    });
</script>

<style scoped></style>
