<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('views.dashboard.title') }}</h1>
            </v-col>
        </v-row>
        <!-- Cards -->
        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-py-4 lg:tw-grid-cols-4 lg:tw-gap-4">
            <CardStatus
                v-for="eventStatus in eventsStatus"
                :key="eventStatus.type"
                :type="eventStatus.type"
                :active="eventStatus.active"
                :pending="eventStatus.pending" />
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-pb-4 lg:tw-grid-cols-3 lg:tw-gap-4">
            <CardEntity
                v-for="(value, key) in entitiesCount"
                :key="key"
                :type="key"
                :value="value" />
        </div>
        <v-row
            id="toolbar-dashboard"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-4">
                <v-btn-toggle color="primary">
                    <v-btn
                        :to="{ name: 'Dashboard-Events' }"
                        size="large"
                        class=""
                        >{{ $t('views.dashboard.activeEvents') }}</v-btn
                    >
                    <v-btn
                        :to="{ name: 'Dashboard-Buildings' }"
                        size="large"
                        class="tw-shrink tw-bg-opacity-0"
                        >{{ $t('views.dashboard.buildingEvents') }}</v-btn
                    >
                </v-btn-toggle>

                <v-btn
                    size="large"
                    @click="$query.toggleView"
                    density="comfortable"
                    class="text-gray rounded-lg tw-border tw-border-gray tw-shadow-none"
                    :icon="$query.isGrid.value ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"></v-btn>

                <v-btn-toggle
                    v-if="$route.name == 'Dashboard-Events'"
                    multiple
                    :model-value="typeFilters"
                    @update:model-value="setFilters"
                    class="tw-ml-auto">
                    <v-btn
                        v-for="(details, type) in eventTypeDetails"
                        :key="type"
                        :color="details.bgColor"
                        :value="type"
                        :class="`text-${typeFilters.includes(type) ? details.color : details.bgColor} tw-border tw-border-gray`"
                        :icon="details.icon"></v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <router-view></router-view>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, onBeforeUnmount } from 'vue';
    import CardStatus from './CardStatus.vue';
    import CardEntity from './CardEntity.vue';
    import { useRoute, useRouter } from 'vue-router';
    import { EntitiesCount, EventStatusCount } from '../../types';
    import { eventTypeDetails } from '@/config/config';
    import _ from 'lodash';
    import { countEventsPerType } from '@/api/events';
    import { useDashboardStore } from '@/store/dashboard';
    import { useQuery } from '@/composables/useQuery';
    import { useSocket } from '@/composables/useSocket';
    import { getEntitiesCount } from '@/api/users';

    const $route = useRoute();
    const $router = useRouter();
    const $query = useQuery();
    const $socket = useSocket();
    const dashboardStore = useDashboardStore();

    const selectedEventsLength = computed(() => {
        return dashboardStore.getSelectedEventsLength;
    });
    const typeFilters = computed(() => {
        return $route.query.type ? _.castArray($route.query.type) : [];
    });

    const eventsStatus = ref<EventStatusCount[]>([]);
    const entitiesCount = ref<EntitiesCount>({
        buildings: 0,
        centrals: 0,
        devices: 0,
    });

    const getStatusCount = async () => {
        const { data } = await countEventsPerType();
        eventsStatus.value = data.data;
    };

    function setFilters(typesSelected: (string | null)[]) {
        const query = {
            ..._.cloneDeep($route.query),
            type: typesSelected,
        };
        $router.replace({ query });
    }

    const fetchEntitiesCount = async () => {
        const { data } = await getEntitiesCount();
        entitiesCount.value = data;
    };

    getStatusCount();
    fetchEntitiesCount();

    $socket.on('events/count-per-type', (data) => {
        getStatusCount();
    });
    $socket.on('users/entities/count', (data) => {
        console.log('users/entities/count', data);

        fetchEntitiesCount();
    });

    onBeforeUnmount(() => {
        $socket.off('events/count-per-type');
        $socket.off('users/entities/count');
    });
</script>

<style scoped></style>
