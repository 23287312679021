// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import AccountConfirmation from '@/views/Auth/AccountConfirmation.vue';
import Register from '@/views/Auth/Register.vue';

// Dashboard
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import DashboardBuilding from '@/views/Dashboard/Buildings.vue';
import DashboardEvents from '@/views/Dashboard/Events.vue';

// Events
import Events from '@/views/Events/Events.vue';

// Building
import Building from '@/views/Buildings/Building.vue';
import BuildingDashboard from '@/views/Buildings/DashboardBuilding.vue';
import Floors from '@/views/Buildings/Floors/Floors.vue';
import InfoBuilding from '@/views/Buildings/InfoBuilding.vue';

// Floor
import Floor from '@/views/Buildings/Floors/Floor.vue';

// Central
import Central from '@/views/Centrals/Central.vue';
import ConfigCentral from '@/views/Centrals/ConfigCentral.vue';
import Enrollment from '@/views/Centrals/Enrollment.vue';
import AddCentral from '@/views/Centrals/AddCentral.vue';

// Zones
import Zones from '@/views/Zones/Zones.vue';
import Zone from '@/views/Zones/Zone.vue';
import ConfigZone from '@/views/Zones/ConfigZone.vue';
import ZoneDevices from '@/views/Zones/Devices.vue';

// Loops
import Loops from '@/views/Loops/Loops.vue';
import Loop from '@/views/Loops/Loop.vue';
import ConfigLoop from '@/views/Loops/ConfigLoop.vue';
import LoopDevices from '@/views/Loops/LoopDevices.vue';

// Devices
import Devices from '@/views/Devices/Devices.vue';
import Device from '@/views/Devices/Device.vue';
import ConfigDevice from '@/views/Devices/ConfigDevice.vue';
import EventsDevice from '@/views/Devices/Events.vue';
import InfoDevice from '@/views/Devices/InfoDevice.vue';

// Users
import Users from '@/views/Users/Users.vue';
import User from '@/views/Users/User.vue';
import CreateUser from '@/views/Users/CreateUser.vue';

// Profile
import Profile from '@/views/Profile/Profile.vue';

import NotFound from '@/views/404.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Dashboard' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
            },
            {
                path: 'account-confirmation',
                name: 'AccountConfirmation',
                component: AccountConfirmation,
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        meta: {
            authRequired: true,
        },
        redirect: { name: 'Dashboard-Events' },
        children: [
            {
                name: 'AddCentral',
                path: 'add-central',
                component: AddCentral,
            },
            {
                name: 'Dashboard',
                path: 'dashboard',
                component: Dashboard,
                redirect: { name: 'Dashboard-Events' },
                children: [
                    {
                        name: 'Dashboard-Events',
                        path: 'events',
                        component: DashboardEvents,
                    },
                    {
                        name: 'Dashboard-Buildings',
                        path: 'buildings',
                        component: DashboardBuilding,
                    },
                ],
            },
            {
                name: 'Events',
                path: 'events',
                component: Events,
            },
            {
                name: 'Building',
                path: 'building/:id',
                component: Building,
                redirect: { name: 'Building-Dashboard' },
                children: [
                    {
                        name: 'Building-Dashboard',
                        path: '',
                        component: BuildingDashboard,
                    },
                    {
                        name: 'Building-Floors',
                        path: 'floors',
                        component: Floors,
                    },
                    {
                        name: 'Building-Info',
                        path: 'info',
                        component: InfoBuilding,
                    },
                ],
            },
            {
                name: 'Floor',
                path: 'floor/:id',
                component: Floor,
            },
            {
                name: 'CentralEnrollment',
                path: 'central/enrollment',
                component: Enrollment,
            },
            {
                name: 'Central',
                path: 'central/:id',
                component: Central,
                redirect: { name: 'Central-Zones' },
                children: [
                    {
                        name: 'Central-Zones',
                        path: 'zones',
                        component: Zones,
                    },
                    {
                        name: 'Central-Loops',
                        path: 'loops',
                        component: Loops,
                    },
                    {
                        name: 'Central-Devices',
                        path: 'devices',
                        component: Devices,
                    },
                    {
                        name: 'Central-Config',
                        path: 'config',
                        component: ConfigCentral,
                    },
                    {
                        name: 'Central-Enrollment',
                        path: 'enrollment',
                        component: Enrollment,
                    },
                ],
            },
            {
                name: 'Zone',
                path: 'zone/:id',
                component: Zone,
                redirect: { name: 'Zone-Devices' },
                children: [
                    {
                        name: 'Zone-Devices',
                        path: 'devices',
                        component: ZoneDevices,
                    },
                    {
                        name: 'Zone-Config',
                        path: 'config',
                        component: ConfigZone,
                    },
                ],
            },
            {
                name: 'Loop',
                path: 'loop/:id',
                component: Loop,
                redirect: { name: 'Loop-Devices' },
                children: [
                    {
                        name: 'Loop-Devices',
                        path: 'devices',
                        component: LoopDevices,
                    },
                    {
                        name: 'Loop-Config',
                        path: 'config',
                        component: ConfigLoop,
                    },
                ],
            },
            {
                name: 'Device',
                path: 'device/:id',
                component: Device,
                redirect: { name: 'Device-Config' },
                children: [
                    {
                        name: 'Device-Config',
                        path: 'config',
                        component: ConfigDevice,
                    },
                    {
                        name: 'Device-Events',
                        path: 'events',
                        component: EventsDevice,
                    },
                    {
                        name: 'Device-Info',
                        path: 'info',
                        component: InfoDevice,
                    },
                ],
            },
            {
                name: 'Users',
                path: 'users',
                component: Users,
            },
            {
                name: 'User',
                path: 'user/:id',
                component: User,
            },
            {
                name: 'Profile',
                path: 'profile',
                component: User,
            },
            {
                path: '/404/:type',
                name: '404',
                component: NotFound,
            },
        ],
    },

    {
        // 404 route for any other route
        path: '/:pathMatch(.*)*',
        redirect: { name: '404', params: { type: 'page' } },
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    const canGoForward = !!window.history.state.forward;
    const canGoBack = !!window.history.state.back;
    // add to the route meta
    to.meta.canGoForward = canGoForward;
    to.meta.canGoBack = canGoBack;
});

export default router;
