<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <template v-if="loop">
            <v-row
                align="start"
                justify="start"
                class="fill-width h-auto justify-start align-content-start">
                <v-col cols="12">
                    <div class="tw-flex tw-items-center tw-gap-4">
                        <v-btn
                            @click="$router.go(-1)"
                            color="gray-300"
                            class="tw-rounded tw-text-xl"
                            size="small"
                            icon="mdi-chevron-left">
                        </v-btn>
                        <h1 class="tw-text-2xl tw-font-bold">{{ loop?.loopID }}</h1>

                        <p
                            v-if="loop?.status"
                            :class="status?.class"
                            class="tw-ml-auto tw-w-full tw-max-w-36 tw-rounded-lg tw-px-2 tw-py-1 tw-text-center tw-font-semibold">
                            {{ status?.status }}
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-breadcrumbs
                        class="tw-p-0"
                        :items="breadcrumbs">
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    class="tw-gap-2 max-xl:tw-grid max-xl:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-flex">
                    <v-btn
                        v-for="(item, index) in navItems"
                        :key="index"
                        :to="item.to"
                        min-width="128"
                        size="large"
                        :color="index === navSelectedIndex ? 'primary' : 'gray-200'"
                        @click="navSelectedIndex = index"
                        >{{ item.title }}</v-btn
                    >
                </v-col>
            </v-row>
            <router-view :loop="loop" />
        </template>
    </v-container>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getLoop } from '@/api/loops';
    import { useI18n } from 'vue-i18n';
    import { CentralDevicesEventsCount } from '@/types';
    import { Loop } from '@/contentTypes';
    import { watch } from 'vue';
    import { computed } from 'vue';
    import { VBreadcrumbs } from 'vuetify/lib/components/index.mjs';
    import { loopStatusDetails } from '@/config/config';
    import { useConfig } from '@/composables/useConfig';
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const $socket = useSocket();
    const $error = useError();
    const $alert = useAlert();
    const $router = useRouter();
    const isLoading = ref(false);
    const { tm, t } = useI18n();

    const $config = useConfig();
    const loopStatusDetails_ = computed(() => $config.loopStatusDetails.value);

    const navItems = computed(() => [
        {
            title: t('views.centrals.shared.buttons.devices'),
            to: { name: 'Loop-Devices', params: { id: $route.params.id } },
        },
        {
            title: t('views.centrals.shared.buttons.configurations'),
            to: { name: 'Loop-Config', params: { id: $route.params.id } },
        },
    ]);
    const navSelectedIndex = ref($route.name == 'Loop-Config' ? 1 : 0);

    const loop = ref<Loop>();

    const breadcrumbs = computed(() => {
        return [
            {
                title: loop.value?.central?.name,
                disabled: false,
                to: { name: 'Central-Zones', params: { id: loop.value?.central?.id } },
            },
            {
                title: loop.value?.loopID,
                disabled: true,
            },
        ] as VBreadcrumbs['$props']['items'];
    });

    async function init(shouldLoad = true) {
        isLoading.value = shouldLoad;
        let query = {
            populate: ['central'],
        };
        try {
            const { data } = await getLoop($route.params.id as string, query);
            loop.value = data.data;
        } catch (error: any) {
            if (error.response.status === 404) {
                $router.push({ name: '404', params: { type: 'loop' } });
            } else {
                $alert.showAlert({
                    text: $error.getErrorMessage(error),
                    type: 'error',
                });
            }
        } finally {
            isLoading.value = false;
        }
    }

    const status = computed(() => {
        if (!loop.value || !loop.value.status) return;
        let data = {
            class: loopStatusDetails_.value[loop.value.status].twBgColor + ' ' + loopStatusDetails_.value[loop.value.status].twColor,
            status: loopStatusDetails_.value[loop.value.status].text,
        };

        return data;
    });

    let currentId: any = null;
    watch(
        () => $route.params.id,
        (newValue, oldValue) => {
            init();
            currentId = $route.params.id;
            $socket.on(`loops/${newValue}`, () => {
                init(false);
            });
            $socket.off(`loops/${oldValue}`);
        },
        {
            immediate: true,
        },
    );

    onBeforeUnmount(() => {
        $socket.off(`loops/${currentId}`);
    });
</script>

<style scoped></style>
