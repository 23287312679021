<template>
    <h1 class="auth-title">{{ $t('auth.welcomeBack') }}</h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form">
        <v-text-field
            v-model.trim="email"
            class="auth-input"
            autocomplete="email"
            :rules="[rules.required, rules.email]"
            type="email"
            name="email"
            :placeholder="$t('fields.email')"></v-text-field>
        <v-text-field
            v-model="password"
            class="auth-input"
            name="current-password"
            autocomplete="current-password"
            :rules="[rules.required]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.password')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>

        <div v-if="alertMessage">
            <v-alert
                :type="alertType"
                variant="tonal"
                density="compact">
                {{ alertMessage }}
                <a v-if="alertSubType == 'your_account_email_is_not_confirmed'" @click="resendEmail" class="tw-cursor-pointer tw-underline">
                    {{ $t('auth.errors.resend_email') }}
                </a>
            </v-alert>
        </div>

        <v-btn
            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn"
            >{{ $t('auth.login') }}</v-btn
        >

        <router-link
            class="tw-text-center tw-underline"
            :to="{ name: 'ForgotPassword' }"
            >{{ $t('auth.forgotPassword') }}</router-link
        >
        <router-link
            class="tw-mt-10 tw-text-center tw-underline"
            :to="{ name: 'Register' }">
            {{ $t('auth.dontHaveAccount') }}
        </router-link>
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';
    import slugify from 'slugify';
    import { useI18n } from 'vue-i18n';

    const authStore = useAuthStore();
    const $router = useRouter();
    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const isPasswordVisible = ref(false);

    const email = ref(import.meta.env.PROD ? '' : 'nibble@iotech.pt');
    const password = ref(import.meta.env.PROD ? '' : 'nibble2023');
    const alertMessage = ref('');
    const alertSubType = ref('');
    const alertType = ref<'success' | 'error'>('error');

    async function onSubmit() {
        if (!form.value) return;

        alertMessage.value = '';
        alertSubType.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await axios.post('/auth/local', {
                identifier: email.value,
                password: password.value,
            });

            authStore.setToken(data.jwt);
            authStore.setUser(data.user);

            $router.push('/');
        } catch (error) {
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            alertType.value = 'error';
            alertSubType.value = messageKey;
            alertMessage.value = $t(`auth.errors.${messageKey}`);
        } finally {
            isLoading.value = false;
        }
    }

    async function resendEmail() {
        isLoading.value = true;
        try {
            await axios.post('/auth/send-email-confirmation', {
                email: email.value,
            });
            alertType.value = 'success';
            alertMessage.value = $t('auth.errors.email_confirmation_sent');
        } catch (error) {
            alertType.value = 'error';
            alertMessage.value = $t('auth.errors.email_confirmation_error');
        } finally {
            alertSubType.value = '';
            isLoading.value = false;
        }
    }
</script>

<style></style>
