<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col cols="12">
                <div class="tw-flex tw-items-center tw-gap-4">
                    <!-- go to previous page -->
                    <v-btn
                        @click="$router.go(-1)"
                        color="gray-300"
                        class="tw-rounded tw-text-xl"
                        size="small"
                        icon="mdi-chevron-left">
                    </v-btn>

                    <h1 class="tw-font-bold sm:tw-text-2xl">{{ deviceInstalled?.name }}</h1>
                    <span class="tw-ml-auto tw-rounded-md tw-bg-yellow-200 tw-px-6 tw-py-2 tw-font-semibold tw-text-yellow">{{ deviceInstalled?.status }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs
                    class="tw-p-0"
                    :items="breadcrumbs">
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="large"
                    class="tw-text-center tw-shadow-none"
                    :color="index === navSelectedIndex ? 'primary' : 'gray-200'"
                    @click="navSelectedIndex = index"
                    >{{ item.title }}</v-btn
                >
            </v-col>
        </v-row>
        <router-view v-if="deviceInstalled" />
    </v-container>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getDeviceInstalled } from '@/api/devicesInstalled';
    import { useI18n } from 'vue-i18n';
    import { DeviceInstalled } from '@/contentTypes';
    import { watch } from 'vue';
    import { computed } from 'vue';
    import { VBreadcrumbs } from 'vuetify/lib/components/index.mjs';
    import { provide } from 'vue';
    import useError from '@/composables/useError';
    import { useAlert } from '@/composables/useAlert';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const { t } = useI18n();
    const $socket = useSocket();
    const $error = useError();
    const $alert = useAlert();
    const $router = useRouter();

    const isLoading = ref(false);
    const deviceInstalled = ref<DeviceInstalled>();

    const navItems = ref([
        {
            title: t('views.centrals.device.buttons.events'),
            to: { name: 'Device-Events', params: { id: $route.params.id } },
        },
        {
            title: t('views.centrals.device.buttons.info'),
            to: { name: 'Device-Info', params: { id: $route.params.id } },
        },
        {
            title: t('views.centrals.device.buttons.configurations'),
            to: { name: 'Device-Config', params: { id: $route.params.id } },
        },
    ]);

    const breadcrumbs = computed(
        () =>
            [
                {
                    title: deviceInstalled.value?.zone?.central?.name,
                    to: { name: 'Central', params: { id: deviceInstalled.value?.zone?.central?.id } },
                },
                {
                    title: deviceInstalled.value?.zone?.name,
                    to: { name: 'Zone', params: { id: deviceInstalled.value?.zone?.id } },
                },
                {
                    title: deviceInstalled.value?.name,
                    disabled: true,
                },
            ] as VBreadcrumbs['$props']['items'],
    );

    // set navSelectedIndex based on route
    const navSelectedIndex = ref(0);
    const index = navItems.value.findIndex((item) => item.to.name === $route.name);
    navSelectedIndex.value = index;

    async function init(shouldLoad = true) {
        isLoading.value = shouldLoad;
        let query = {
            populate: {
                zone: {
                    fields: ['id', 'name'],
                    populate: {
                        central: {
                            fields: ['id', 'name'],
                        },
                    },
                },
                device: {
                    populate: {
                        picture: true,
                    },
                },
                configurations: {
                    populate: {
                        configuration: true,
                    },
                },
            },
        };

        try {
            const { data } = await getDeviceInstalled($route.params.id as string, query);
            deviceInstalled.value = data.data;
        } catch (error: any) {
            if (error.response.status === 404) {
                $router.push({ name: '404', params: { type: 'device' } });
            } else {
                $alert.showAlert({
                    text: $error.getErrorMessage(error),
                    type: 'error',
                });
            }
        } finally {
            isLoading.value = false;
        }
    }

    provide('deviceInstalled', deviceInstalled);

    let currentId: any = null;
    watch(
        () => $route.params.id,
        (newValue, oldValue) => {
            init();
            currentId = newValue;
            $socket.on(`devices-installed/${newValue}`, () => {
                init(false);
            });
            $socket.off(`devices-installed/${oldValue}`);
        },
        {
            immediate: true,
        },
    );

    onBeforeUnmount(() => {
        $socket.off(`devices-installed/${currentId}`);
    });
</script>

<style scoped></style>
