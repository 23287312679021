<template>
    <v-card
        @click="$router.push({ name: 'Loop', params: { id: loop.id } })"
        color="gray"
        variant="outlined"
        class="tw-p-8">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <p class="tw-text-center tw-text-lg tw-font-semibold tw-text-dark">{{ loop.loopID }}{{ inDev ? `(${loop.id})` : '' }}</p>
            <div>
                <p class="tw-text-center tw-font-medium tw-text-dark-200">{{ $t('views.loops.version') }}: {{ loop.firmware }}</p>
                <p class="tw-text-center tw-font-medium tw-text-dark-200">{{ $t('fields.devices') }} ({{ loop.devices?.length }})</p>
            </div>

            <p
                v-if="status"
                :class="status.class"
                class="tw-m-auto tw-w-full tw-max-w-36 tw-rounded-lg tw-px-2 tw-py-1 tw-text-center tw-font-semibold">
                {{ status.status }}
            </p>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { Loop } from '@/contentTypes';
    import { computed } from 'vue';
    import { useConfig } from '../../composables/useConfig';

    const $config = useConfig();
    const loopStatusDetails = computed(() => $config.loopStatusDetails.value);

    const props = defineProps<{
        loop: Loop;
    }>();

    const inDev = import.meta.env.DEV;

    const status = computed(() => {
        if (!props.loop.status) return;
        let data = {
            class: loopStatusDetails.value[props.loop.status].twBgColor + ' ' + loopStatusDetails.value[props.loop.status].twColor,
            status: loopStatusDetails.value[props.loop.status].text,
        };

        return data;
    });
</script>

<style scoped></style>
