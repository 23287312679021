<template>
    <div class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
        <h1 class="tw-text-7xl tw-font-semibold tw-text-primary">404</h1>
        <p class="tw-my-4 tw-text-xl tw-font-medium">{{ $t(`notFound.${$route.params.type}`, $t(`notFound.page`)) }}</p>
        <!-- back button -->
        <v-btn
            @click="$router.push({ name: 'Dashboard' })"
            color="primary"
            size="large"
            class="tw-rounded-lg tw-text-lg">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('notFound.backToDashboard') }}
        </v-btn>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
