// Utilities
import { DeviceInstalled } from '@/contentTypes';
import { defineStore } from 'pinia';

export const useBlueprintStore = defineStore('blueprint', {
    state: () => ({
        devices: [] as DeviceInstalled[],
        touchedDevice: null as DeviceInstalled | null,
    }),
    getters: {
        getDevicesForBlueprint(): DeviceInstalled[] {
            return this.devices.filter((device) => !!device.blueprintData);
        },
        getDevicesForSidebar(): DeviceInstalled[] {
            return this.devices.filter((device) => !device.blueprintData);
        },
        getTouchedDevice(): DeviceInstalled | null {
            return this.touchedDevice;
        },
    },
    actions: {
        setDevices(value: DeviceInstalled[]) {
            this.devices = value;
        },
        updateDevice(id: number, blueprintData: any) {
            const device = this.devices.find((device) => device.id === id);
            if (device) {
                device.blueprintData = blueprintData;
            }
        },
        setTouchedDevice(device: DeviceInstalled | null) {
            this.touchedDevice = device;
        },
    },
});
