<template>
    <v-row
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-grow max-sm:tw-w-full"
                :model-value="search"
                @update:model-value="onSearch"
                clearable
                :placeholder="$t('shared.search')"></v-text-field>
        </v-col>
    </v-row>
    <v-row v-if="isLoading">
        <v-col cols="12">
            <v-progress-linear
                :active="isLoading"
                color="primary"
                indeterminate></v-progress-linear>
        </v-col>
    </v-row>

    <div class="tw-my-8 tw-flex tw-flex-col tw-gap-8">
        <RowFloor
            v-for="floor in floorsFiltered"
            :key="floor.id"
            :floor="floor"></RowFloor>
    </div>

    <div v-if="!floorsFiltered.length">
        <v-row>
            <v-col cols="12">
                <p class="tw-text-center">
                    {{ hasFiltersApplied ? $t('views.floors.noFloorsWithFilters') : $t('views.floors.noFloors') }}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import RowFloor from './RowFloor.vue';
    import { useAlert } from '@/composables/useAlert';
    import { computed, watch, ref, onBeforeUnmount, inject, Ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import _ from 'lodash';
    import { countDevicesPerCentral } from '@/api/buildings';
    import { BuildingFloorDevicesCount } from '@/types';
    import { useError } from '@/composables/useError';
    import { useSocket } from '@/composables/useSocket';
    import { ContentType } from '@/contentTypes';

    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const $error = useError();
    const $socket = useSocket();

    const isLoading = ref(false);
    const search = ref('');
    const searchTimeoutId = ref<NodeJS.Timeout>();
    const floors = ref<BuildingFloorDevicesCount[]>([]);

    const _building = inject<Ref<ContentType<'Building'>>>('building');

    const floorsFiltered = computed(() => {
        if (!search.value) return floors.value;

        return floors.value.filter((floor) => {
            return (floor.name ?? '').toLowerCase().includes(search.value.toLowerCase());
        });
    });

    const hasFiltersApplied = computed(() => {
        return !!search.value;
    });

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str;
            setRouterQuery();
        }, 250);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    async function init(shouldLoad = true) {
        try {
            isLoading.value = shouldLoad;
            const { data } = await countDevicesPerCentral($route.params.id.toString());
            floors.value = data.data;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error),
            });
        } finally {
            isLoading.value = false;
        }
    }

    let currentId: any = null;
    watch(
        () => $route.params.id,
        (newValue, oldValue) => {
            init();
            currentId = newValue;
            $socket.on(`buildings/${currentId}/centrals-devices-count`, () => {
                init(false);
            });
            $socket.off(`buildings/${oldValue}/centrals-devices-count`);
        },
        {
            immediate: true,
        },
    );

    onBeforeUnmount(() => {
        $socket.off(`buildings/${currentId}/centrals-devices-count`);
    });
</script>

<style scoped></style>
