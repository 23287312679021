<template>
    <v-navigation-drawer
        width="400"
        location="right"
        floating
        mobile-breakpoint="xl"
        class="tw-bg-gray-100 tw-p-10 tw-pt-12"
        v-model="isOpen">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl tw-font-semibold">{{ $t('shared.filters') }}</h1>
                <v-btn
                    @click="isOpen = false"
                    color="gray-300"
                    density="comfortable"
                    class="rounded-lg tw-text-dark tw-shadow-none"
                    icon="mdi-close"></v-btn>
            </div>
            <div v-if="props.enabledFilters.includes('zone')">
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.zone') }}</v-label>
                <ioAutocomplete
                    clearable
                    @resetFunction="(e) => (resetZones = e)"
                    @change="onZonesChange"
                    :fetch-items="fetchZones"
                    v-model="selectedZone" />
            </div>
            <div v-if="props.enabledFilters.includes('type')">
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.centrals.devices.table.type') }}</v-label>
                <v-select
                    :items="$config.deviceTypes.value"
                    v-model="selectedType"
                    clearable
                    @update:model-value="onTypeChange"
                    item-title="text"
                    item-value="value"></v-select>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { getZones } from '@/api/zones';
    import { onBeforeUnmount, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useConfig } from '@/composables/useConfig';
    import { get, has, isArray, set } from 'lodash';
    import { flatten, unflatten } from 'flat';
    import { useSocket } from '@/composables/useSocket';

    const isOpen = defineModel({
        default: false,
    });

    const props = defineProps({
        enabledFilters: {
            type: Array,
            default: () => ['zone', 'type'],
        },
    });

    const $route = useRoute();
    const $router = useRouter();
    const $config = useConfig();
    const $socket = useSocket();

    const selectedZone = ref<number>();
    const selectedType = ref<string>();

    const resetZones = ref<() => void>();

    function onZonesChange(value: number[] | number | null) {
        const query = unflatten($route.query);

        set(query as object, 'filters.zone', value ?? undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    function onTypeChange(value: string | null) {
        const query = unflatten($route.query);

        set(query as object, 'filters.type', value ?? undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    async function fetchZones({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query: any = {
            central: {
                id: $route.params.id,
            },
        };

        if (search) {
            set(query, 'name.$containsi', search);
        }

        if (isArray(ids) && ids.length > 0) {
            set(query, 'id.$in', ids);
        }

        return getZones({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    function init() {
        const query: object = unflatten($route.query);

        if (has(query, 'filters.zone')) {
            const zone = get(query, 'filters.zone', undefined);
            selectedZone.value = zone ? parseInt(zone) : undefined;
        }

        if (has(query, 'filters.type')) {
            const type = get(query, 'filters.type', undefined);
            selectedType.value = type ? type : undefined;
        }
    }

    init();

    $socket.on('zones', () => {
        if (resetZones.value) {
            resetZones.value();
        }
    });

    onBeforeUnmount(() => {
        $socket.off('zones');
    });
</script>

<style scoped></style>
