<template>
    <!-- Título e botões -->
    <v-row
        id="zones-search"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-bg-white">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-min-w-60"
                :placeholder="$t('shared.search')"
                :model-value="search"
                clearable
                @update:model-value="onSearch"></v-text-field>
            <v-btn
                size="large"
                @click="$query.toggleView"
                color="gray-200"
                density="comfortable"
                class="text-gray rounded-lg tw-shadow-none"
                :icon="$query.isGrid.value ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"></v-btn>
            <v-btn-toggle
                multiple
                v-model="typeFilters"
                @update:model-value="setFilters"
                class="tw-ml-auto">
                <v-btn
                    v-for="(details, type) in eventTypeDetails"
                    :key="type"
                    :color="details.bgColor"
                    :value="type"
                    :class="`text-${typeFilters.includes(type) ? details.color : details.bgColor} tw-border tw-border-gray`"
                    :icon="details.icon"></v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>

    <v-row
        align="start"
        justify="start"
        v-show="$query.isGrid.value"
        class="fill-width h-auto justify-start align-content-start">
        <v-col
            v-if="zonesFiltered.length > 0"
            cols="12"
            md="6"
            xl="4"
            xxl="3"
            v-for="(zone, i) in zonesFiltered"
            :key="i">
            <CardCentralZones :zone="zone" />
        </v-col>
        <v-col
            v-else-if="!isLoading"
            cols="12">
            <p
                v-show="hasFilters"
                class="tw-text-center">
                {{ $t('views.zones.noZonesWithFilters') }}
            </p>
            <p
                v-show="!hasFilters"
                class="tw-text-center">
                {{ $t('views.zones.noZones') }}
            </p>
        </v-col>
        <v-col
            v-if="isLoading"
            cols="12"
            class="tw-flex tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-col>
    </v-row>
    <v-row
        align="start"
        justify="start"
        v-show="!$query.isGrid.value"
        class="fill-width h-auto justify-start align-content-start">
        <v-col cols="12">
            <v-data-table-virtual
                id="zonesTable"
                hover
                color="primary"
                density="comfortable"
                v-model:sortBy="sortBy"
                :loading="isLoading"
                :no-data-text="hasFilters ? $t('views.zones.noZonesWithFilters') : $t('views.zones.noZones')"
                :headers="headers"
                :items="zonesFiltered"
                fixed-header
                @click:row="onRowClick">
                <template #item.enable="{ item }">
                    <span :class="status(item?.enable ? 'enabled' : 'disabled').class">{{ item.enable ? $t(`ZoneEnabled.enabled`) : $t(`ZoneEnabled.disabled`) }}</span>
                </template>
                <template #item.events="{ item }">
                    <div class="tw-flex tw-gap-4">
                        <v-badge
                            v-for="(event, i) in getEventTypes(item)"
                            :content="event.count"
                            :color="event.count ? 'primary' : 'gray'"
                            :class="`tw-z-${getEventTypes(item).length - i}`"
                            @click.stop="
                                $router.push({
                                    name: 'Zone-Devices',
                                    params: { id: item.id },
                                    query: { type: event.type },
                                })
                            "
                            :key="event.type">
                            <v-icon
                                :color="event.color"
                                :icon="event.icon"
                                :class="`${event.twBgColor} tw-rounded-md tw-p-4`"
                                size="large"></v-icon>
                        </v-badge>
                    </div>
                </template>
                <template #item.actions="{ item }">
                    <v-btn
                        :to="{
                            name: 'Zone-Devices',
                            params: { id: item.id },
                        }"
                        icon="mdi-chevron-right"
                        color="primary"
                        variant="text"
                        size="large">
                    </v-btn>
                </template>
                <template #body.append>
                    <tr v-if="isLoading">
                        <td
                            :colspan="headers && headers.length ? headers.length : 1"
                            class="tw-text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"></v-progress-circular>
                        </td>
                    </tr>
                </template>
            </v-data-table-virtual>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { ref, computed, watch, onBeforeUnmount } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { CentralZonesEventsCount } from '@/types';
    import CardCentralZones from '@/views/General/CardCentralZones.vue';
    import { eventTypeDetails, zoneEnabledDetails } from '@/config/config';
    import _ from 'lodash';
    import { countCentralZonesEvents } from '@/api/centrals';
    import { useI18n } from 'vue-i18n';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { useQuery } from '@/composables/useQuery';
    import { useDisplay } from '@/composables/useDisplay';
    import { useSocket } from '@/composables/useSocket';

    const { toolbarHeight: toolbarZonesHeight } = useDisplay('zones-search');

    const { t } = useI18n();
    const $router = useRouter();
    const $socket = useSocket();
    const $route = useRoute();
    const $query = useQuery();

    const isLoading = ref(false);
    const search = ref('');
    const central = ref<CentralZonesEventsCount>();
    const searchTimeoutId = ref<NodeJS.Timeout>();
    const sortBy = ref([
        {
            key: 'name',
            order: 'asc',
        },
    ]);

    const typeFilters = ref<string[]>([]);

    const hasFilters = computed(() => typeFilters.value.length > 0 || search.value.length > 0);

    const headers = computed(() => {
        const baseHeaders = [
            {
                title: t('views.centrals.zones.table.zone'),
                key: 'name',
            },
            {
                title: t('views.centrals.zones.table.status'),
                key: 'enable',
            },
            {
                title: t('views.centrals.zones.table.devices'),
                key: 'devices.count',
            },
            {
                title: t('views.centrals.zones.table.occurrences'),
                key: 'events',
                sortable: false,
            },
            {
                title: '',
                key: 'actions',
                align: 'end',
                sortable: false,
            },
        ] as VDataTableServer['headers'];

        if (import.meta.env.DEV) {
            baseHeaders.unshift({
                title: 'ID',
                key: 'id',
            });
        }

        return baseHeaders;
    });

    const zonesFiltered = computed(() => {
        if (!central.value) return [];
        return central.value.zones
            .filter((zone) => zone.name.toLowerCase().includes(search.value.toLowerCase()))
            .filter((zone) => {
                if (typeFilters.value.length === 0) return true;
                return typeFilters.value.some((type) => {
                    return zone.events[type as keyof typeof zone.events] > 0;
                });
            });
    });

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function setFilters(typesSelected: (string | null)[]) {
        const query = {
            ..._.cloneDeep($route.query),
            type: typesSelected,
        };
        $router.replace({ query });
    }

    function getEventTypes(zone: CentralZonesEventsCount['zones'][0]) {
        const eventTypes = [];
        for (const type in zone.events) {
            eventTypes.push({
                type,
                // @ts-ignore
                count: zone.events[type],
                ...eventTypeDetails[type as keyof typeof eventTypeDetails],
            });
        }
        return eventTypes;
    }

    async function init(shouldLoad = true) {
        isLoading.value = shouldLoad;
        const { data } = await countCentralZonesEvents($route.params.id as string);
        central.value = data.data;
        isLoading.value = false;
    }

    function status(status: keyof typeof zoneEnabledDetails) {
        return zoneEnabledDetails[status];
    }

    function onRowClick(e: Event, o: any) {
        $router.push({
            name: 'Zone-Devices',
            params: { id: o.item.id },
        });
    }

    let currentId: any = null;
    watch(
        () => $route.params.id,
        (newValue, oldValue) => {
            currentId = newValue;
            $socket.on(`centrals/${newValue}/zone-events-count`, () => {
                init(false);
            });
            if (oldValue) $socket.off(`centrals/${oldValue}/zone-events-count`);
            init();
        },
        {
            immediate: true,
        },
    );

    search.value = _.get($route, 'query.search', '') as string;
    typeFilters.value = $route.query.type ? (_.castArray($route.query.type).filter((type) => type !== null) as string[]) : [];

    onBeforeUnmount(() => {
        $socket.off(`centrals/${currentId}/zone-events-count`);
    });
</script>

<style lang="scss">
    @screen sm {
        #zonesTable .v-table__wrapper {
            overflow: unset !important;
        }
        #zonesTable .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarZonesHeight));
            z-index: 5;
        }
    }
</style>
