<template>
    <v-row
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start">
        <v-col
            v-if="isLoading"
            cols="12"
            class="tw-flex tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-col>
        <v-col
            v-for="(loop, i) in loops"
            :key="i"
            cols="12"
            md="6"
            xl="4"
            xxl="3">
            <CardLoop :loop="loop" />
        </v-col>
        <v-col
            v-if="!isLoading && !loops.length"
            class="tw-text-center"
            cols="12">
            <p>{{ $t('views.loops.noLoops') }}</p>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { getLoops } from '@/api/loops';
    import { useRoute } from 'vue-router';
    import { onBeforeUnmount, ref } from 'vue';
    import CardLoop from './CardLoop.vue';
    import { Loop } from '@/contentTypes';
    import _ from 'lodash';
    import { pageSizes } from '@/config/config';
    import { VDataTableOptions } from '@/types';
    import { computed } from 'vue';
    import { APIRequestParams } from '../../contentTypes';
    import { watch } from 'vue';
    import { useAlert } from '../../composables/useAlert';
    import useError from '@/composables/useError';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();

    const $alert = useAlert();
    const $error = useError();
    const $socket = useSocket();

    const isLoading = ref(false);
    const loops = ref<Loop[]>([]);
    const total = ref(0);
    const pageSize = ref(pageSizes[2]);

    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'loopID',
            order: 'asc',
        },
    ]);

    const query = computed(() => {
        return {
            filters: {
                central: $route.params.id,
            },
            pagination: {
                pageSize: pageSize.value,
            },
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
            populate: {
                devices: {
                    fields: ['id'],
                },
            },
        } as APIRequestParams<'Loop'>;
    });

    watch(
        query,
        () => {
            init();
        },
        {
            deep: true,
        },
    );

    async function init(shouldLoad = true) {
        isLoading.value = shouldLoad;
        try {
            const { data } = await getLoops(query.value);
            loops.value = data.data;
            total.value = data.meta.pagination.total;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error, 'views.loops.errors'),
            });
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        () => $route.params.id,
        () => {
            init();
        },
        {
            immediate: true,
        },
    );

    $socket.on(`loops`, () => {
        init(false);
    });

    onBeforeUnmount(() => {
        $socket.off(`loops`);
    });
</script>

<style scoped></style>
