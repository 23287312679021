<template>
    <div>
        <ioImg
            :draggable="true"
            @dragstart="onDragStart"
            @touchstart="onTouch"
            class="tw-cursor-grab tw-select-all tw-rounded-2xl tw-border tw-border-gray-300"
            :media="device.device?.picture"></ioImg>
        <p class="tw-mt-2 tw-break-words tw-text-center tw-text-lg tw-font-medium">{{ device.name }}</p>
    </div>
</template>

<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import { ContentType } from '@/contentTypes';
    import { useBlueprintStore } from '@/store/blueprint';

    const props = defineProps<{
        device: ContentType<'DeviceInstalled'>;
    }>();

    const emit = defineEmits(['dragstart']);

    const blueprintStore = useBlueprintStore();

    function onTouch() {
        blueprintStore.setTouchedDevice(props.device);
        emit('dragstart');
    }

    function onDragStart(event: DragEvent) {
        event.dataTransfer?.setData('device', JSON.stringify(props.device));
    }
</script>

<style scoped></style>
