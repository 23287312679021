import { APIResponseMany, APIResponseSingle, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import { DataWrapper, CentralZonesEventsCount, CentralDevicesEventsCount } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/centrals';
export const API_REF = ContentTypesUID.Central;

export async function getCentrals(query: APIRequestParams<'Central'> = {}): AxiosPromise<APIResponseMany<'Central'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getCentral(id: string, query: APIRequestParams<'Central'> = {}): AxiosPromise<APIResponseSingle<'Central'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function countCentralZonesEvents(id: string, query = {}): AxiosPromise<DataWrapper<CentralZonesEventsCount>> {
    return axios.get(`${API_URL}/${id}/zone-events-count?${qs.stringify(query)}`);
}

export async function countCentralDevicesEvents(id: string, query: APIRequestParams<'DeviceInstalled'> = {}): AxiosPromise<CentralDevicesEventsCount> {
    return axios.get(`${API_URL}/${id}/device-events-count?${qs.stringify(query)}`);
}

export async function updateCentral(id: number, data: any): AxiosPromise<APIResponseSingle<'Central'>> {
    return axios.put(`${API_URL}/${id}`, { data });
}

export async function adminEnrollment(
    sn: string,
    data: any,
): AxiosPromise<{
    ok: boolean;
    central: APIResponseSingle<'Central'>;
}> {
    return axios.post(`${API_URL}/${sn}/admin-enrollment`, data);
}

export async function resetCentral(sn: string): AxiosPromise<{
    ok: boolean;
}> {
    return axios.post(`${API_URL}/cmd`, { type: 'cmd', sub_type: 'reset', sn, payload: { reset: true } });
}

export async function silenceCentral(sn: string): AxiosPromise<{
    ok: boolean;
}> {
    return axios.post(`${API_URL}/cmd`, { type: 'cmd', sub_type: 'silence', sn, payload: { silence: true } });
}
