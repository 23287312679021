<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-title>
                {{ props.building.name }}
                <template v-slot:actions="{ expanded }">
                    <div class="tw-flex tw-items-center">
                        <v-checkbox
                            :disabled="isLoading"
                            @click.stop
                            @update:model-value="onBuildingCheck(props.building.id || 0)"
                            :value="props.building.id"
                            v-model="buildings"
                            class="tw-flex tw-justify-end"
                            color="primary"></v-checkbox>
                        <v-icon>mdi-chevron-down</v-icon>
                    </div>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row no-gutters>
                    <template v-for="floor in props.building.floors">
                        <v-col
                            cols="12"
                            v-if="floor.centrals?.length || false">
                            <h3>{{ floor.name }}</h3>
                            <v-row no-gutters>
                                <v-col
                                    v-for="central in floor.centrals"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    xl="3">
                                    <v-checkbox
                                        :disabled="isLoading"
                                        @click.stop
                                        @update:model-value="onCentralCheck"
                                        color="primary"
                                        v-model="centrals"
                                        :value="central.id"
                                        :label="central.name"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
    import { ContentType } from '@/contentTypes';
    import { computed, nextTick, ref } from 'vue';
    import { inject } from 'vue';
    import { PropType } from 'vue';

    const buildings = defineModel<number[]>('buildings', {
        required: true,
    });
    const centrals = defineModel<number[]>('centrals', {
        required: true,
    });

    const isLoading = inject<boolean>('isLoading');

    const props = defineProps({
        building: {
            type: Object as PropType<ContentType<'Building'>>,
            required: true,
        },
    });

    const buildingCentrals = computed(() => {
        return (props.building.floors?.flatMap((floor) => floor.centrals?.map((central) => central.id)) as number[]) || ([] as number[]);
    });

    const buildingCheckedCentals = computed(() => {
        return centrals.value.filter((central) => buildingCentrals.value.includes(central));
    });

    function onBuildingCheck(buildingId: number) {
        if (!buildings.value?.includes(buildingId)) {
            // add all centrals to the list
            centrals.value = centrals.value.concat(buildingCentrals.value);
        } else {
            // remove all centrals from the list
            centrals.value = centrals.value.filter((central) => !buildingCentrals.value.includes(central));
        }
    }

    async function onCentralCheck() {
        await new Promise((resolve) => setTimeout(resolve, 100));

        // if any central is checked, then the building is checked
        if (buildingCheckedCentals.value.length > 0) {
            // if the building is not checked, then check it
            if (!buildings.value?.includes(props.building.id || 0)) {
                buildings.value = buildings.value.concat(props.building.id || 0);
            }
        }
    }

    if (buildings.value?.includes(props.building.id || 0)) {
        centrals.value = centrals.value.concat(buildingCentrals.value);
    }
</script>

<style scoped></style>
